<template>
  <section class="about" style="background: #FFF; position: relative;">
    <v-img
      v-if="info.banners[0]"
      :src="info.banners[0].img"
      class="tnf-top-banner"
      >
    </v-img>
    <v-container  grid-list-md>
      <v-layout wrap>
        <v-flex xs12 sm12 md12 lg12 mt-2 class="tnf-contact-top">
          <h3>查找离您最近的门店<span>Find The Store</span></h3>
        </v-flex>
        <template v-for="(plist, pk) in stores">
          <v-flex xs12 sm12 md12 lg12 text-center class="tnf-shop-province-div">
            <div class="tnf-shop-province">{{ pk }}</div>
            <span class="tnf-shop-province-underline"></span>
          </v-flex>
          <v-layout wrap class="tnf-shop-city-div">
            <v-flex xs12 sm12 md4 lg4 v-for="(clist, ck) in plist">
              <v-layout wrap style="margin-bottom: 40px;">
                <v-flex xs12 sm12 md12 lg12 class="tnf-shop-city">{{ ck }}</v-flex>
                <template v-for="store in clist">
                  <v-flex xs12 sm12 md12 lg12 class="tnf-shop-name">{{ store.name }}</v-flex>
                  <v-flex xs12 sm12 md12 lg12 class="tnf-shop-addr"><v-icon size="16">mdi-map-marker</v-icon>{{ store.addr }}</v-flex>
                  <v-flex xs12 sm12 md12 lg12 class="tnf-shop-tel"><v-icon size="16">mdi-phone</v-icon>{{ store.tel }}</v-flex>
                </template>
              </v-layout>
            </v-flex>
          </v-layout>
        </template>
      </v-layout>
    </v-container>
    <v-layout wrap style="background:#0C65A5;">
      <v-container  grid-list-md>
        <v-layout wrap class="tnf-contact-content">
          <v-flex xs12 sm12 md12 lg12 >
            <v-layout wrap>
              <v-flex xs12 sm12 md2 lg2 class="tnf-contact-title">公司地址：</v-flex>
              <v-flex xs12 sm12 md10 lg10 class="tnf-contact-value text-left">上海市松江区佘山工业园明业路38号</v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 mt-6 mb-6>
            <v-layout wrap>
              <v-flex xs12 sm12 md2 lg2 class="tnf-contact-title">联系方式：</v-flex>
              <v-flex xs12 sm12 md10 lg10 class="text-left">
                <v-layout wrap>
                  <v-flex xs12 sm12 md4 lg4>
                    <v-layout wrap>
                      <v-flex xs6 sm6 md12 lg12 class="tnf-contact-subtitle">联系邮箱</v-flex>
                      <v-flex xs6 sm6 md12 lg12 class="tnf-contact-value">tryba-market@trybachina.com</v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm12 md4 lg4>
                    <v-layout wrap>
                      <v-flex xs6 sm6 md12 lg12 class="tnf-contact-subtitle">销售热线</v-flex>
                      <v-flex xs6 sm6 md12 lg12 class="tnf-contact-value">400-161-0919</v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm12 md4 lg4>
                    <v-layout wrap>
                      <v-flex xs6 sm6 md12 lg12 class="tnf-contact-subtitle">售后热线</v-flex>
                      <v-flex xs6 sm6 md12 lg12 class="tnf-contact-value">400-180-0810</v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 mb-12>
            <v-layout wrap>
              <v-flex xs12 sm12 md2 lg2 class="tnf-contact-title">关注我们：</v-flex>
              <v-flex xs12 sm12 md10 lg10 class="text-left" style="height:120px;">
                <v-layout wrap>
                  <v-flex xs4 sm4 md4 lg4 @mouseenter="changeQr('qr', 'gzh')" @mouseleave="changeQr('icon', 'gzh')">
                    <img :src="imgGzh" :height="heightGzh" />
                  </v-flex>
                  <v-flex xs4 sm4 md4 lg4 @mouseenter="changeQr('qr', 'xcx')" @mouseleave="changeQr('icon', 'xcx')">
                    <img :src="imgXcx" :height="heightXcx" />
                  </v-flex>
                  <v-flex xs4 sm4 md4 lg4 @mouseenter="changeQr('qr', 'wb')" @mouseleave="changeQr('icon', 'wb')">
                    <img :src="imgWb" :height="heightWb" />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    </v-layout>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 6,
    imgGzh: 'https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/icon_gzh.png',
    imgXcx: 'https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/icon_xcx.png',
    imgWb: 'https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/icon_wb.png',
    heightGzh: 40,
    heightXcx: 40,
    heightWb: 40,
    stores: []
  }),
  created () {
    this.getStore()
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      return {
        banners: banners
      }
    }
  },
  methods: {
    getStore () {
      this.https.get('tnf_store', { city: this.param }).then(response => {
        if (response.code === 0 && response.data) {
          this.stores = response.data
        }
      })
    },
    changeQr (type, name) {
      switch (name) {
        case 'gzh':
          this.imgGzh = type === 'qr' ? 'https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/qr_gzh.jpg' : 'https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/icon_gzh.png'
          this.heightGzh = type === 'qr' ? 120 : 40
          break
        case 'xcx':
          this.imgXcx = type === 'qr' ? 'https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/qr_xcx.jpg' : 'https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/icon_xcx.png'
          this.heightXcx = type === 'qr' ? 120 : 40
          break
        case 'wb':
          this.imgWb = type === 'qr' ? 'https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/qr_wb.jpg' : 'https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/icon_wb.png'
          this.heightWb = type === 'qr' ? 120 : 40
          break
      }
    }
  }
}
</script>
<style lang="css">
  @import '../css/contact.css';
</style>
